import * as React from 'react';

function SvgDesignIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M15.857 3h2.94C20.013 3 21 3.995 21 5.223v2.965a2.214 2.214 0 01-2.203 2.223h-2.94a2.214 2.214 0 01-2.204-2.223V5.223c0-1.228.987-2.223 2.204-2.223zM5.204 3h2.939c1.217 0 2.204.995 2.204 2.223v2.965a2.214 2.214 0 01-2.204 2.223H5.204A2.214 2.214 0 013 8.188V5.223C3 3.995 3.987 3 5.204 3zm0 10.59h2.939c1.217 0 2.204.994 2.204 2.223v2.964A2.214 2.214 0 018.143 21H5.204A2.214 2.214 0 013 18.777v-2.964c0-1.229.987-2.224 2.204-2.224zm10.653 0h2.94c1.216 0 2.203.994 2.203 2.223v2.964A2.213 2.213 0 0118.797 21h-2.94a2.214 2.214 0 01-2.204-2.223v-2.964c0-1.229.987-2.224 2.204-2.224z"
          stroke="currentColor"
          strokeWidth={1.8}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SvgDesignIcon;
