import config from "../config";
import store from "../configureStore";
import { fetchData } from "./ajaxActions";

function ajaxError(name, error, type, opts) {
  return {
    error,
    options: opts,
    type: `${type}_${name}_ERROR`,
  };
}

function ajaxSuccess(name, response, type, opts, data) {
  return (dispatch) => {
    dispatch({
      data,
      options: opts,
      response: response,
      type: `${type}_${name}_SUCCESS`,
    });
    if (response.tableupdates) {
      response.tableupdates.forEach((store) => {
        dispatch(fetchData(store));
      });
    }
  };
}

function ajaxRequest(name, options, type) {
  return {
    options,
    type: `${type}_${name}_REQUEST`,
  };
}

export function makeAjaxRequest(name, opts, type, data, callback = null) {
  // Log the initial inputs to the function
  if(opts.command === 'permissions.get') console.log('makeAjaxRequest called with:', { name, opts, type, data, callback });
  
  // Log the current token from the store
  const token = store.getState().token.token;
  if(opts.command === 'permissions.get') console.log('Current token:', token);

  let bodyParams = Object.keys(opts)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(opts[key]);
    })
    .join("&");
  bodyParams +=
    "&" + encodeURIComponent("token") + "=" + encodeURIComponent(token);

  // Log the final bodyParams to be sent in the request
  if(opts.command === 'permissions.get') console.log('Final bodyParams:', bodyParams);

  return (dispatch) => {
    dispatch(ajaxRequest(name, opts, type));
    let fetchURL = config.url + "/apps/controlcenter/php/ajax.php";
    
    // Log the URL being fetched
    if(opts.command === 'permissions.get') console.log('Fetching URL:', fetchURL);

    return fetch(fetchURL, {
      method: "post",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: bodyParams,
    })
      .then((response) => {
        // Log the HTTP status of the response
        if(opts.command === 'permissions.get') console.log('Response status:', response.status);

        if (response.status >= 200 && response.status < 300) {
          response
            .json()
            .then((json) => {
              // Log the JSON response from the server
              if(opts.command === 'permissions.get') console.log('JSON response:', json);

              if (json.success) {
                dispatch(ajaxSuccess(name, json, type, opts, data));
                if (callback) callback();
              } else {
                // Log the error returned from the server
                if(opts.command === 'permissions.get') console.log('Server returned an error:', json.errors);
                const error = new Error(json.errors[0].error);
                dispatch(ajaxError(name, error, type, opts));
              }
            })
            .catch((error) => {
              if(opts.command === 'permissions.get') console.log("JSON error in response", error);
            });
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          dispatch(ajaxError(name, error, type, opts));
          throw error;
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
  };
}
