import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import "ag-grid/dist/styles/ag-grid.css";
import "ag-grid/dist/styles/ag-theme-material.css";
import "react-select/dist/react-select.cjs";
import "react-datepicker/dist/react-datepicker.css";
// import * as serviceWorker from './serviceWorker';
import Login from "./components/login";
import store from "./configureStore";
import { Provider } from "react-redux";

window.reduxStore = store;

ReactDOM.render(
  <Provider store={store}>
    <Login />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
