import * as React from 'react';

function SvgArrowUpIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 8 12"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M1.17 1a1 1 0 000 1.41L4.71 6 1.17 9.54a1 1 0 101.42 1.41l4.24-4.24a1 1 0 000-1.42L2.59 1a1 1 0 00-1.42 0z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgArrowUpIcon;
