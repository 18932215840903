import * as React from 'react';

function SvgArrowDownIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 8"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M11 1.17a1 1 0 00-1.41 0L6 4.71 2.46 1.17a1 1 0 10-1.41 1.42l4.24 4.24a1 1 0 001.42 0L11 2.59a1 1 0 000-1.42z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgArrowDownIcon;
