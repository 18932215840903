import * as React from 'react';

function SvgSmallCheckboxIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="icon"
      {...props}
    >
      <defs>
        <filter
          x="-125%"
          y="-125%"
          width="350%"
          height="350%"
          filterUnits="objectBoundingBox"
          id="small-checkbox-icon_svg__b"
        >
          <feGaussianBlur stdDeviation={20} in="SourceAlpha" result="shadowBlurInner1" />
          <feOffset dx={-9} dy={-9} in="shadowBlurInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2={-1}
            k3={1}
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.960784314 0 0 0 0 0.968627451 0 0 0 0 1 0 0 0 1 0"
            in="shadowInnerInner1"
            result="shadowMatrixInner1"
          />
          <feGaussianBlur stdDeviation={15} in="SourceAlpha" result="shadowBlurInner2" />
          <feOffset dx={18} dy={18} in="shadowBlurInner2" result="shadowOffsetInner2" />
          <feComposite
            in="shadowOffsetInner2"
            in2="SourceAlpha"
            operator="arithmetic"
            k2={-1}
            k3={1}
            result="shadowInnerInner2"
          />
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
            in="shadowInnerInner2"
            result="shadowMatrixInner2"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixInner1" />
            <feMergeNode in="shadowMatrixInner2" />
          </feMerge>
        </filter>
        <rect id="small-checkbox-icon_svg__a" x={0} y={0} width={20} height={20} rx={4} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <use fill="#FFF" xlinkHref="#small-checkbox-icon_svg__a" />
        <use
          fill="#000"
          filter="url(#small-checkbox-icon_svg__b)"
          xlinkHref="#small-checkbox-icon_svg__a"
        />
        <use stroke="#D8DBEA" xlinkHref="#small-checkbox-icon_svg__a" />
        <g fillRule="nonzero">
          <path
            d="M8.205 15.011a.831.831 0 01-.607-.266l-4.04-4.297a.832.832 0 011.214-1.14l3.425 3.65 6.99-7.647a.831.831 0 111.23 1.114L8.82 14.737a.831.831 0 01-.607.274h-.008z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSmallCheckboxIcon;
