import * as React from 'react';

function SvgGraphIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 19 20"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M8.523.215a1.417 1.417 0 011.501 0c.455.285.707.81.64 1.333h0v17.187l-.02.134A1.395 1.395 0 019.264 20c-.71 0-1.315-.54-1.4-1.265h0V1.548l-.007-.143c.005-.474.25-.93.666-1.19zM.663 5.747a1.388 1.388 0 011.484 0c.454.287.709.808.656 1.333h0v11.655l-.019.134A1.395 1.395 0 011.404 20C.677 20 .07 19.46.004 18.735h0V7.08l-.006-.143a1.42 1.42 0 01.664-1.19zm15.602 6.123a1.414 1.414 0 012.159 1.333h0v5.532l-.022.134A1.418 1.418 0 0117.024 20c-.727 0-1.333-.54-1.4-1.265h0v-5.532l-.01-.147a1.36 1.36 0 01.65-1.186z"
        stroke="currentColor"
        strokeWidth={0.2}
        fillRule="nonzero"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgGraphIcon;
