import React, { Component } from "react";
import { DialogContainer } from "react-md";
import { Button } from "./Button";
import App from "../App";
import { connect } from "react-redux";
import Config from "../config";
import PropTypes from "prop-types";
import { fetchToken } from "../actions/tokenactions";
import "../styles/loginStyles.scss";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isResetting: false,
      username: Config.userName,
      password: Config.passWord,
      alertVisible: false,
      emailConfirmed: false,
      loginAlertVisible: false,
      logoutAlertVisible: false,
    };
  }

  componentDidMount() {
    this.setState({
      error: "",
      loginAlertVisible: false,
      logoutAlertVisible: false,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.token &&
      this.props.token.token &&
      this.props.token.token > "" &&
      (!prevProps.token || this.props.token.token !== prevProps.token.token)
    ) {
      if (this.props.token.alertMessage) {
        this.setState({ alertVisible: true });
      }
    }

    if (!prevState.loginAlertVisible && !this.state.loginAlertVisible) {
      this.setState({ loginAlertVisible: true });
    }

    if (
      this.props.token &&
      this.props.token.error === "Session logged out" &&
      !prevState.logoutAlertVisible &&
      !this.state.logoutAlertVisible
    ) {
      this.setState({ logoutAlertVisible: true });
    }
  }

  handleSubmit = (e) => {
    // e.preventDefault();
    this.props.dispatch(
      fetchToken({
        command: "login",
        user: this.state.username,
        pwd: this.state.password,
      })
    );

    if (
      this.props.token &&
      this.props.token.error &&
      this.props.token.error > ""
    ) {
      this.setState({ error: this.props.token.error });
    }
  };

  hideLoginAlert = () => {
    this.setState({ error: "", loginAlertVisible: false });
  };

  hideLogoutAlert = () => {
    this.setState({ error: "", logoutAlertVisible: false });
  };

  handleForcedLogin = () => {
    this.hideLoginAlert();
    // Force login new session
    this.props.dispatch(
      fetchToken({
        command: "login",
        user: this.state.username,
        pwd: this.state.password,
        forcedLogin: true,
      })
    );
  };

  sendResetPassword = () => {
    // send email to API for email sending
    const email = this.state.username;

    // add params
    const options = {
      command: "pass.send.email",
      email,
    };

    // construct URL
    let url = Config.url + "/portal/php/api.php";
    let bodyParams = Object.keys(options)
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(options[key]);
      })
      .join("&");
    url = url + "?" + bodyParams;

    // display message with email instructions
    this.setState({
      emailConfirmed: true,
    });

    // send request
    fetch(url, {
      method: "get",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response
            .json()
            .then((json) => {
              // email sent
            })
            .catch((error) => {
              // error
              console.log(error);
              return;
            });
        } else {
          console.log(response);
          return;
        }
      })
      .catch((error) => {
        // error
        console.log(error);
        return;
      });
  };
  hideAlert = () => {
    this.setState({ alertVisible: false });
  };

  toggleResetPassword = () => {
    this.setState({
      isResetting: !this.state.isResetting,
      emailConfirmed: false,
    });
  };

  render() {
    if (
      this.props.token &&
      this.props.token.token &&
      this.props.token.token > ""
    ) {
      return (
        <React.Fragment>
          <App
            username={this.state.username}
            user={this.props.token}
            dispatch={this.props.dispatch}
          />
          <DialogContainer
            id="alert"
            visible={this.state.alertVisible}
            title={this.props.token.alertTitle || "System alert"}
            onHide={this.hideAlert}
            modal
            actions={[
              {
                onClick: this.hideAlert,
                primary: true,
                children: "OK",
              },
            ]}
          >
            <div>{this.props.token.alertMessage}</div>
          </DialogContainer>
        </React.Fragment>
      );
    } else if (this.state.isResetting) {
      let errorMessage;
      if (this.props.token && this.props.token.error) {
        errorMessage = (
          <div style={{ color: "red", width: "100%", textAlign: "center" }}>
            {this.props.token.error}
          </div>
        );
      }

      return (
        <div className="h-screen">
          <div className="py-16">
            <div
              className="flex items-stretch p-10 mx-auto bg-white rounded-xl md-text-container"
              style={{
                flexFlow: "column wrap",
              }}
            >
              <img
                src="./images/jensenpos-logo.png"
                style={{ margin: "0 auto" }}
                alt="JensenPOS"
              />
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: 24,
                  marginTop: -90,
                  marginBottom: "3rem",
                }}
              >
                Licensing and Venue Management
              </div>
              {!this.state.emailConfirmed && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    id="Email"
                    className={`form-input`}
                    placeholder="Email"
                    required
                    defaultValue={this.state.username}
                    onChange={(e) =>
                      this.setState({ username: e.target.value })
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.sendResetPassword();
                      }
                    }}
                    autoFocus
                  />
                  {this.state.emailConfirmed ? errorMessage : ""}
                  <div
                    style={{
                      marginTop: 20,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={this.sendResetPassword}
                      className={`btn-login`}
                    >
                      Reset Password
                    </Button>
                    <Button
                      onClick={this.toggleResetPassword}
                      className={`btn-login`}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
              {this.state.emailConfirmed && (
                <div
                  style={{
                    width: "85%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: "bold",
                      marginBottom: "2rem",
                      textAlign: "center",
                    }}
                  >
                    Password Reset Request Received
                  </h3>
                  <span style={{ textAlign: "center" }}>
                    If the email address entered matches a user login, you will
                    receive an email with instructions on how to reset your
                    password.
                  </span>
                  <div
                    style={{
                      marginTop: 20,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={this.toggleResetPassword}
                      className={`btn-login`}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      let errorMessage;
      if (this.props.token && this.props.token.error) {
        if (this.props.token.error === "Session exists") {
          errorMessage = (
            <DialogContainer
              id="login-alert"
              visible={this.state.loginAlertVisible}
              title={"Login Confirmation"}
              onHide={this.hideLoginAlert}
              modal
              actions={[
                {
                  primary: true,
                  children: "Yes",
                  onClick: this.handleForcedLogin,
                },
                {
                  secondary: true,
                  children: "No",
                  onClick: this.hideLoginAlert,
                },
              ]}
            >
              <div>
                You're logged in on another computer. Do you want to log out
                from previous device and continue your login?
              </div>
            </DialogContainer>
          );
        } else if (this.props.token.error === "Session logged out") {
          errorMessage = (
            <DialogContainer
              id="logout-alert"
              visible={this.state.logoutAlertVisible}
              title={"Remote Logout"}
              onHide={this.hideLogoutAlert}
              modal
              actions={[
                {
                  primary: true,
                  children: "OK",
                  onClick: this.hideLogoutAlert,
                },
              ]}
            >
              <div>
                You have been logged out due to a login on another computer
              </div>
            </DialogContainer>
          );
        } else {
          errorMessage = (
            <div style={{ color: "red", width: "100%", textAlign: "center" }}>
              {this.props.token.error}
            </div>
          );
        }
      }
      return (
        <div className="h-screen">
          <div className="py-16">
            <div
              className="flex items-stretch p-10 mx-auto bg-white rounded-xl md-text-container"
              style={{ flexFlow: "column wrap" }}
            >
              <img
                src="./images/jensenpos-logo.png"
                style={{ margin: "0 auto" }}
                alt="JensenPOS"
              />
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: 24,
                  marginTop: -90,
                  marginBottom: "3rem",
                }}
              >
                Licensing and Venue Management
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Email"
                  required
                  className="form-input"
                  defaultValue={this.state.username}
                  onChange={(e) => this.setState({ username: e.target.value })}
                  autoFocus
                />
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  required
                  className="form-input"
                  defaultValue={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.handleSubmit();
                    }
                  }}
                />
                {errorMessage}
                <div
                  style={{
                    marginTop: 10,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button onClick={this.handleSubmit} className={`btn-login`}>
                    Login
                  </Button>
                  <p
                    onClick={this.toggleResetPassword}
                    className={`forgotPass`}
                  >
                    Forgot Password?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

Login.propTypes = {
  dispatch: PropTypes.func,
  token: PropTypes.object,
};

const mapStateToProps = (state) => ({ token: state.token });

export default connect(mapStateToProps)(Login);
