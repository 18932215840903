import * as React from 'react';

function SvgRefineIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g transform="translate(5 6)" fill="currentColor">
          <rect width={14} height={2.333} rx={1} />
          <rect x={2.333} y={4.667} width={9.333} height={2.333} rx={1} />
          <rect x={3.889} y={9.333} width={6.222} height={2.333} rx={1} />
        </g>
      </g>
    </svg>
  );
}

export default SvgRefineIcon;
