import * as React from 'react';

function SvgRefreshIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M19.353 13.281a.887.887 0 00-1.11.577 6.336 6.336 0 01-6.096 4.366 6.301 6.301 0 01-6.372-6.212A6.301 6.301 0 0112.147 5.8a6.443 6.443 0 014.127 1.482l-1.926-.32a.887.887 0 00-1.02.737.887.887 0 00.736 1.02l3.763.622h.15a.887.887 0 00.302-.054.293.293 0 00.089-.053.692.692 0 00.177-.097l.08-.098c0-.044.08-.08.116-.133.035-.053 0-.089.044-.124a1.19 1.19 0 00.062-.16l.666-3.55a.903.903 0 00-1.775-.337l-.24 1.287a8.174 8.174 0 00-5.351-1.997A8.076 8.076 0 004 12.012a8.076 8.076 0 008.147 7.987 8.094 8.094 0 007.827-5.609.887.887 0 00-.621-1.109z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgRefreshIcon;
