import * as React from 'react';

function SvgCloseIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M13.95 12.712L10.237 9l3.713-3.712a.875.875 0 10-1.238-1.238L9 7.763 5.288 4.05A.875.875 0 104.05 5.288L7.763 9 4.05 12.712a.875.875 0 101.238 1.238L9 10.237l3.712 3.713a.875.875 0 101.238-1.238z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgCloseIcon;
