import * as React from 'react';

function SvgSystemIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M19.47 18.21l1.26 1.26s0 0 0 0c.36.36.36.9 0 1.26a.87.87 0 01-1.26 0l-9.45-9.45c-.72.45-1.62.72-2.52.72C4.98 12 3 10.02 3 7.5S4.98 3 7.5 3 12 4.98 12 7.5c0 .9-.27 1.8-.72 2.52l4.41 4.41 1.26-1.26a.87.87 0 011.26 0c.36.36.36.9 0 1.26l-1.26 1.26 1.26 1.26 1.26-1.26a.87.87 0 011.26 0c.36.36.36.9 0 1.26l-1.26 1.26zM7.5 4.8c-1.53 0-2.7 1.17-2.7 2.7 0 1.53 1.17 2.7 2.7 2.7 1.53 0 2.7-1.17 2.7-2.7 0-1.53-1.17-2.7-2.7-2.7z"
          fill="currentColor"
          fillRule="nonzero"
          stroke="#979797"
          strokeWidth={0.2}
        />
      </g>
    </svg>
  );
}

export default SvgSystemIcon;
