import uuid from "uuid";

export default function createAjaxReducer(name) {
  return function (
    state = {
      items: [],
      byGUID: {},
    },
    action
  ) {
    switch (action.type) {
      case `FETCH_${name}_REQUEST`:
        return { ...state, status: "FETCHING" };

      case `FETCH_${name}_ERROR`:
        alert(action.error);
        return { ...state, status: "ERROR" };

      case `FETCH_${name}_SUCCESS`: {
        const byGUID = {};
        let fields = [];
        if (action.response.fields) fields = action.response.fields;
        let items = action.response.results;
        state.items.forEach((item, i) => {
          if (item.isNewRecord && i === 0) {
            items.unshift(item);
          } else if (item.isNewRecord) {
            items.push(item);
          }
        });
        for (let item of items) {
          byGUID[item.fdGUID] = item;
        }
        return {
          ...state,
          items,
          fields,
          status: "FETCHED",
          byGUID,
        };
      }

      case `INSERT_${name}_REQUEST`:
        return state;

      case `INSERT_${name}_ERROR`:
        alert(action.error);
        return state;

      case `INSERT_${name}_SUCCESS`: {
        let idx = state.items.findIndex(function (entry) {
          let id, field;
          if (name === "USERS") {
            id = entry.fdUserID;
            field = "fdUserID";
          } else if (entry.fdID) {
            id = entry.fdID;
            field = "fdID";
          } else {
            id = entry.fdGUID;
            field = "fdGUID";
          }
          return id === action.data[field];
        });

        if (name === "ACCOUNTS") {
          idx = state.items.findIndex(function (entry) {
            return entry.fdAccountCode === action.data.fdAccountCode;
          });
          if (idx === -1) {
            idx = state.items.findIndex(function (entry) {
              return entry.fdAccountCode === "NEW";
            });
          }
        }

        if (action.response.results) {
          return {
            ...state,
            items: action.response.results,
            fields: [...state.fields],
            status: "FETCHED",
          };
        }

        let newItems = [...state.items];
        if (idx === -1) {
          if (action.options.addLast)
            return {
              ...state,
              items: [
                ...state.items,
                { ...action.data, fdGUID: action.data.fdGUID },
              ],
            };

          if (action.response.fields) {
            let item = { ...action.data, fdGUID: action.data.fdGUID };
            for (let field in action.response.fields) {
              item[field] = action.response.fields[field];
            }
            return { ...state, items: [item, ...state.items] };
          }

          return {
            ...state,
            items: [
              {
                ...action.data,
                fdGUID: action.data.fdGUID,
              },
              ...state.items,
            ],
          };
        } else {
          if (action.response.fields) {
            let item = {
              ...newItems[idx],
              ...action.data,
              fdGUID: action.data.fdGUID,
            };

            for (let field in action.response.fields) {
              item[field] = action.response.fields[field];
            }

            newItems[idx] = item;
          } else {
            newItems[idx] = {
              ...newItems[idx],
              ...action.data,
              ...action.response.data,
              fdGUID: action.data.fdGUID,
            };
          }
        }

        return { ...state, items: newItems };
      }
      case `CREATE_LOCAL_ACCOUNTS`: {
        if (action.addLast)
          return {
            ...state,
            items: [
              ...state.items,
              {
                ...action.data,
                //         fdAccountCode: 'NEW'
              },
            ],
          };

        return {
          ...state,
          items: [
            {
              ...action.data,
              //       fdAccountCode: 'NEW'
            },
            ...state.items,
          ],
        };
      }

      case `UPDATE_LOCAL_ACCOUNTS`: {
        const newItems = [...state.items];
        let idx = newItems.findIndex(function (entry) {
          return entry.fdAccountCode === action.guid;
        });
        if (idx === -1) return state;
        newItems[idx] = { ...newItems[idx], [action.field]: action.value };
        return { ...state, items: newItems };
      }

      case `CREATE_LOCAL_${name}`: {
        if (action.addLast)
          return {
            ...state,
            items: [
              ...state.items,
              {
                ...action.data,
                fdGUID: uuid().toUpperCase(),
              },
            ],
          };

        return {
          ...state,
          items: [
            {
              ...action.data,
              fdGUID: uuid().toUpperCase(),
            },
            ...state.items,
          ],
        };
      }

      case `UPDATE_LOCAL_${name}`: {
        const newItems = [...state.items];
        let idx = newItems.findIndex(function (entry) {
          return entry.fdGUID === action.guid;
        });
        if (idx === -1) return state;
        newItems[idx] = { ...newItems[idx], [action.field]: action.value };
        return { ...state, items: newItems };
      }

      case `DELETE_APPSLIST_SUCCESS`: {
        let idx = state.items.findIndex(function (entry) {
          return entry.fdName === action.options.name;
        });

        return {
          ...state,
          items: [...state.items.slice(0, idx), ...state.items.slice(idx + 1)],
        };
      }

      case `DELETE_${name}_REQUEST`:
        return state;

      case `DELETE_${name}_ERROR`:
        alert(action.error);
        return state;

      case `DELETE_${name}_SUCCESS`: {
        let idx = state.items.findIndex(function (entry) {
          let id;
          if (name === "USERS") id = entry.fdUserID;
          else if (entry.fdID) id = entry.fdID;
          else id = entry.fdGUID;
          return id === action.options.guid;
        });
        if (name === "ACCOUNTS") {
          idx = state.items.findIndex(function (entry) {
            return entry.fdAccountCode === action.options.account;
          });
        }
        if (name === "APIUSERPERMISSIONS") {
          idx = state.items.findIndex(function (entry) {
            return (
              entry.fdAPI === action.options.fdAPI &&
              entry.fdUserID === action.options.guid
            );
          });
        }

        if (idx === -1) {
          console.log("Eror removing - could not locate record");
          return state;
        }

        return {
          ...state,
          items: [...state.items.slice(0, idx), ...state.items.slice(idx + 1)],
        };
      }

      case `UPDATE_${name}_REQUEST`:
        return state;

      case `UPDATE_${name}_ERROR`: {
        let newItems = [...state.items];
        let accountcode = action.options.accountcode;

        let idx = newItems.findIndex(function (entry) {
          return entry.fdAccountCode === accountcode;
        });

        //Do NOT do this -->
        //newItems[idx][action.options.field] = action.options.oldvalue
        //this actually mutates the state, because the array is made of objects!
        //so do this -->
        newItems[idx] = {
          ...newItems[idx],
          [action.options.field]: action.options.oldvalue,
        };

        return { ...state, items: newItems };
      }

      case `UPDATE_ACCOUNTS_SUCCESS`: {
        let newItems = [...state.items];
        let accountcode = action.options.accountcode;

        let idx = newItems.findIndex(function (entry) {
          return entry.fdAccountCode === accountcode;
        });

        newItems[idx] = {
          ...newItems[idx],
          [action.options.field]: action.options.value,
        };

        if (action.options.sortBy)
          newItems.sort(
            (a, b) => a[action.options.sortBy] - b[action.options.sortBy]
          );
        return { ...state, items: newItems };
      }

      case `UPDATE_${name}_SUCCESS`: {
        let newItems = [...state.items];
        let idx = state.items.findIndex(function (entry) {
          let id;
          if (name === "USERS") id = entry.fdUserID;
          else if (entry.fdID) id = entry.fdID;
          else id = entry.fdGUID;
          return id === action.options.guid;
        });

        newItems[idx] = {
          ...newItems[idx],
          [action.options.field]: action.options.value,
        };

        if (action.options.sortBy)
          newItems.sort(
            (a, b) => a[action.options.sortBy] - b[action.options.sortBy]
          );
        return { ...state, items: newItems };
      }

      case `MULTIUPDATE_${name}_SUCCESS`: {
        let newItems = [...state.items];
        let guid = action.options.guid;

        let idx = newItems.findIndex(function (entry) {
          return entry.fdGUID === guid;
        });

        newItems[idx] = { ...newItems[idx], ...action.data };

        if (action.options.sortBy)
          newItems.sort(
            (a, b) => a[action.options.sortBy] - b[action.options.sortBy]
          );
        return { ...state, items: newItems };
      }

      case `LINK_UPDATE_${name}_SUCCESS`: {
        let groupGuid = action.options.groupGuid;
        let groupField = action.options.groupField;

        let newItems = state.items.filter(
          (item) => item[groupField] !== groupGuid
        );
        for (let row of action.response.results) {
          newItems.push(row);
        }
        return { ...state, items: newItems };
      }

      default:
        return state;
    }
  };
}
