import * as React from 'react';

function SvgPlusCopyIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M15.125 8.125h-5.25v-5.25a.875.875 0 10-1.75 0v5.25h-5.25a.875.875 0 100 1.75h5.25v5.25a.875.875 0 101.75 0v-5.25h5.25a.875.875 0 100-1.75z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgPlusCopyIcon;
