import * as React from 'react';

function SvgPromotionIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M12.714 3.441l2.316 4.656a.795.795 0 00.597.437l5.186.749c.21.029.4.139.528.306a.772.772 0 01-.085 1.031l-3.755 3.64a.76.76 0 00-.227.695l.905 5.128a.786.786 0 01-.651.892.862.862 0 01-.516-.08l-4.624-2.417a.78.78 0 00-.742 0l-4.652 2.434a.811.811 0 01-1.077-.33.792.792 0 01-.081-.5l.905-5.127a.787.787 0 00-.226-.696l-3.783-3.638a.786.786 0 010-1.115.91.91 0 01.452-.223l5.186-.75a.813.813 0 00.597-.436l2.317-4.656a.784.784 0 01.457-.4.798.798 0 01.61.044.818.818 0 01.363.356z"
        stroke="currentColor"
        strokeWidth={1.8}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgPromotionIcon;
