import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import tokenReducer from "./reducers/tokenreducers";
import createAjaxReducer from "./reducers/createAjaxReducer";

let rootReducer = combineReducers({
  token: tokenReducer,
  accounts: createAjaxReducer("ACCOUNTS"),
  appslist: createAjaxReducer("APPSLIST"),
  billingschemes: createAjaxReducer("BILLINGSCHEMES"),
  dashboards: createAjaxReducer("DASHBOARDS"),
  modulelist: createAjaxReducer("MODULELIST"),
  accountapps: createAjaxReducer("ACCOUNTAPPS"),
  licencehistory: createAjaxReducer("LICENCEHISTORY"),
  licencesummary: createAjaxReducer("LICENCESUMMARY"),
  users: createAjaxReducer("USERS"),
  apiusers: createAjaxReducer("APIUSERS"),
  apiuserpermissions: createAjaxReducer("APIUSERPERMISSIONS"),
  apiregistry: createAjaxReducer("APIREGISTRY"),
  apidocuments: createAjaxReducer("APIDOCUMENTS"),
  apphistory: createAjaxReducer("APPHISTORY"),
  userapps: createAjaxReducer("USERAPPS"),
  apiipaddresses: createAjaxReducer("APIIPADDRESSES"),
  userapiipaddresses: createAjaxReducer("USERAPIIPADDRESSES"),
  apicompanyregistration: createAjaxReducer("APICOMPANYREGISTRATION"),
  pagepermissions: createAjaxReducer("PAGEPERMISSIONS"),
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
window.reduxStore = store;
export default store;
