import React from "react";
import NavLink from "./NavLink";
import { withRouter } from "react-router";
import jensenIMG from "../images/jensen-icon.png";
import jensenIMGMob from "../images/logo-mob.png";
import jensenAvatar from "../images/avatar.png";

class TopLevelNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      myNavItems: props.myNavItems,
      children: props.children,
      className: props.className,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  // For now this is the decider on whether the menu is expanded.
  // You could also have a button to toggle expanded on/off instead.
  checkIsDash() {
    const { location } = this.props;
    const expanded = location.pathname.includes("/dashboard");
    if (expanded !== this.state.expanded) {
      this.setState({ expanded });
    }
  }

  // componentDidMount() {
  //   this.checkIsDash()
  // }

  // componentDidUpdate() {
  //   this.checkIsDash()
  // }

  get navState() {
    return this.state.expanded ? "md:w-60" : "md:w-24";
  }

  toggleMenu = () => {
    let expanded = !this.state.expanded;
    this.setState({ expanded: expanded });
  };

  render() {
    return (
      <div className="py-10">
        <div
          className={`flex flex-col gap-6 justify-center items-center transition-all ${this.navState}`}
        >
          <div
            className="list-none"
            onClick={() => {
              const l = window.location.origin;
              window.open(l);
            }}
          >
            <img
              className="object-contain w-12 mb-8 "
              src={jensenIMG}
              alt=""
              id="image-logo"
            />
            {this.state.expanded ? (
              <span className="text-lg font-bold text-light">
                Jensen
                <span className="text-blue-400">POS</span>
              </span>
            ) : (
              <div style={{ height: 25 }}></div>
            )}
          </div>
          <button onClick={this.toggleMenu}>
            <svg
              className={`hamburger ${this.state.expanded ? "on" : ""}`}
              viewBox="0 0 120 120"
            >
              <g>
                <path
                  className="line top"
                  d="M35,35h50c14.1,0,50.6,13,20.5,53.5s-121.9,21.6-94.4-40.3S111.6,8.4,85,35L35,85"
                />
                <path
                  className="line bottom"
                  d="M35,85h50c14.1,0,50.6-13,20.5-53.5S-16.4,9.9,11.1,71.8S111.6,111.6,85,85L35,35"
                />
                <line className="line cross" x1="35" y1="60" x2="85" y2="60" />
              </g>
            </svg>
          </button>
          <nav className={`top-level-nav md:pr-0  w-full pl-6`}>
            <ul className="flex flex-col gap-4">
              {this.state.myNavItems.map((props) => (
                <NavLink
                  className="top-level-nav__item"
                  labelsActive={this.state.expanded}
                  {...props}
                  key={props.to}
                />
              ))}
            </ul>
          </nav>
          <div
            className={`navbar__user-profile mb-8 flex ${
              this.state.expanded && "px-6 w-full"
            } justify-center items-center relative z-20`}
          >
            <div
              className={` flex gap-2 items-center ${
                this.state.expanded &&
                "bg-black w-full bg-opacity-20 rounded-3xl"
              }`}
            >
              <div
                className="flex w-full navbar__user-profile__controls"
                onClick={() => window.location.reload()}
              >
                <div className="relative flex-shrink-0 navbar__user-profile__avatar w-max">
                  <i className="absolute w-5 h-5 bg-green-400 border-2 rounded-full status-indicator border-quaternary -top-1 -right-1" />
                  <img
                    className="object-contain rounded-full w-14 h-14"
                    src={jensenAvatar}
                    alt="avatar"
                  />
                </div>

                {this.state.expanded && (
                  <span className="self-center w-full p-4 text-light">
                    Logout
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={`navbar__logo ${this.state.expanded && "px-6"}`}>
            <img
              className="flex-shrink-0 w-14 h-14"
              src={jensenIMGMob}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TopLevelNav);
