import { makeAjaxRequest } from "./createAjaxActions";

const stores = {
  ACCOUNTS: {
    fetchCommand: "accounts.list",
    removeCommand: "accounts.delete",
    updateCommand: "accounts.update",
    createCommand: "accounts.add",
    keyField: "fdAccountCode",
  },
  APPSLIST: {
    fetchCommand: "apps.list",
    createCommand: "apps.add",
    updateCommand: "appsregister.update",
    removeCommand: "apps.remove",
  },
  ACCOUNTAPPS: {
    fetchCommand: "accapps.licences",
    createCommand: "accapps.addlicence",
    updateCommand: "accapps.updatelicence",
    removeCommand: "accapps.removelicences",
    keyField: "fdName",
  },
  BILLINGSCHEMES: {
    fetchCommand: "billing.get",
  },
  LICENCESUMMARY: {
    fetchCommand: "accapps.summary",
    createCommand: "accapps.addlicence",
  },
  LICENCEHISTORY: {
    fetchCommand: "licence.history",
  },
  USERS: {
    fetchCommand: "users.get",
    updateCommand: "user.update",
    createCommand: "user.create",
    removeCommand: "user.remove",
    keyField: "fdUserID",
  },
  APIUSERS: {
    fetchCommand: "users.api.get",
    updateCommand: "user.api.update",
    createCommand: "user.api.create",
    removeCommand: "user.api.remove",
    keyField: "fdID",
  },
  APIUSERPERMISSIONS: {
    fetchCommand: "users.api.permissions.get",
    updateCommand: "user.api.permissions.update",
    createCommand: "user.api.permissions.create",
    removeCommand: "user.api.permissions.remove",
  },
  APIREGISTRY: {
    fetchCommand: "api.endpoints.get",
    updateCommand: "api.endpoint.update",
    createCommand: "api.endpoint.create",
    removeCommand: "api.endpoint.remove",
    keyField: "fdID",
  },
  APIDOCUMENTS: {
    fetchCommand: "api.documents.get",
    updateCommand: "api.document.update",
    createCommand: "api.document.create",
    removeCommand: "api.document.remove",
    keyField: "fdID",
  },
  APPHISTORY: {
    fetchCommand: "app.history.get",
    updateCommand: "app.history.update",
    createCommand: "app.history.create",
    removeCommand: "app.history.remove",
  },
  USERAPPS: {
    fetchCommand: "userapps.get",
    table: "userapps",
    keyField: "fdGUID",
  },
  MODULELIST: {
    fetchCommand: "modules.list",
  },
  DASHBOARDS: {
    fetchCommand: "dashboards.get",
  },
  APIIPADDRESSES: {
    fetchCommand: "api.ip.get",
    createCommand: "api.ip.create",
    updateCommand: "api.ip.update",
    removeCommand: "api.ip.remove",
    keyField: "fdGUID",
  },
  USERAPIIPADDRESSES: {
    fetchCommand: "user.api.ip.get",
    createCommand: "user.api.ip.create",
    updateCommand: "user.api.ip.update",
    removeCommand: "user.api.ip.remove",
    table: "apiuseripmap",
    keyField: "fdGUID",
  },
  APICOMPANYREGISTRATION: {
    fetchCommand: "api.companies.get",
    createCommand: "api.company.create",
    updateCommand: "api.company.update",
    removeCommand: "api.company.remove",
    table: "apiuserregistration",
    keyField: "fdGUID",
  },
  PAGEPERMISSIONS: {
    fetchCommand: "permissions.get",
  },
};

export function fetchData(name, options) {
  return makeAjaxRequest(
    name,
    {
      command: stores[name].fetchCommand,
      ...options,
    },
    "FETCH"
  );
}

export function createLocalRecord(name, data, addLast) {
  return {
    data,
    type: `CREATE_LOCAL_${name}`,
    addLast,
  };
}

export function updateLocalRecord(name, guid, field, value) {
  return {
    type: `UPDATE_LOCAL_${name}`,
    guid,
    field,
    value,
  };
}

export function updateLinkTable(
  name,
  groupGuid,
  groupField,
  itemGuids,
  itemField,
  account
) {
  console.log("updateLinkTable", name, groupGuid, groupField, itemGuids, itemField, account)
  return makeAjaxRequest(
    name,
    {
      command: "linktable.update",
      table: stores[name].table,
      groupGuid,
      groupField,
      itemGuids,
      itemField,
      account,
    },
    "LINK_UPDATE"
  );
}
export function updateSequence() {}

export function removeRecord(name, record) {
  return makeAjaxRequest(
    name,
    {
      command: stores[name].removeCommand,
      guid: record[stores[name].keyField],
      account: record.fdAccountCode,
    },
    "DELETE"
  );
}

export function createRecord(name, data, addLast, account, callback=null) {
  return makeAjaxRequest(
    name,
    {
      command: stores[name].createCommand,
      ...data,
      addLast,
      account,
    },
    "INSERT",
    data,
    callback
  );
}

export function updateRecord(name, guid, field, value, old, account, callback=null) {
  return makeAjaxRequest(
    name,
    {
      command: stores[name].updateCommand,
      guid,
      field,
      value,
      accountcode: guid,
      account,
    },
    "UPDATE",
    null,
    callback
  );
}

export function multiUpdateRecord(name, guid, updates, account) {
  return makeAjaxRequest(
    name,
    {
      command: stores[name].updateCommand,
      guid,
      accountcode: guid,
      updates: JSON.stringify(updates),
      account,
    },
    "MULTIUPDATE",
    updates
  );
}
export function restart(account, devices, type) {
  return makeAjaxRequest(
    "noname",
    {
      command: `restart.device`,
      account,
      devices,
      type,
    },
    "notype"
  );
}

export function updateVersion(account, devices, version, type) {
  return makeAjaxRequest(
    "noname",
    {
      command: `update.device`,
      account,
      devices,
      version,
      type,
    },
    "UPDATE_VERSION"
  );
}
