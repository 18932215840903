import * as React from 'react';

function SvgStockControlIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M15.568 3c2.892 0 4.624 1.756 4.632 4.689h0v8.622c0 2.933-1.724 4.689-4.624 4.689h0-2.57a.656.656 0 01-.58-.656c0-.336.25-.618.58-.655h2.562c2.21 0 3.323-1.137 3.323-3.378h0V7.69c0-2.241-1.113-3.378-3.323-3.378h0-6.944c-2.21 0-3.33 1.137-3.33 3.378h0v8.622c0 2.241 1.12 3.378 3.33 3.378.33.037.58.32.58.655 0 .337-.25.62-.58.656C5.732 21 4 19.244 4 16.311h0V7.69C4 4.749 5.732 3 8.624 3h6.944zm-.235 12.159c.355 0 .643.292.643.652 0 .36-.288.651-.643.651h0-6.49a.647.647 0 01-.642-.651c0-.36.288-.652.643-.652h6.489zm0-3.815c.248-.027.49.091.622.306a.667.667 0 010 .7.648.648 0 01-.622.306h0-6.49A.656.656 0 018.265 12c0-.336.25-.619.58-.656h6.489zM11.32 7.538c.33.037.58.32.58.655 0 .337-.25.62-.58.656h0-2.469a.656.656 0 01-.579-.656c0-.336.25-.618.58-.655h2.468z"
          fill="currentColor"
          fillRule="nonzero"
          stroke="none"
          strokeWidth={0.4}
        />
      </g>
    </svg>
  );
}

export default SvgStockControlIcon;
