import * as React from 'react';

function SvgLogoutIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M7 6a1 1 0 100-2H5a1 1 0 00-1 1v14a1 1 0 001 1h2a1 1 0 000-2H6V6h1zm13.82 5.42l-2.82-4a1 1 0 10-1.63 1.16L18.09 11H10a1 1 0 000 2h8l-1.8 2.4a1 1 0 101.6 1.2l3-4a1 1 0 00.02-1.18z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgLogoutIcon;
