import * as React from 'react';

function SvgReportingIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M19.703 9.111a.66.66 0 01.653.659h0v5.825c0 3.334-2.084 5.405-5.425 5.405h0-2.657a.667.667 0 01-.668-.659.675.675 0 01.668-.666h2.657c2.633 0 4.088-1.445 4.112-4.08h0V9.77a.66.66 0 01.66-.659zm-5.432-5.413c.366.005.66.302.66.667a.658.658 0 01-.668.659h0-5.846c-2.633 0-4.089 1.452-4.089 4.087h0v6.484c0 2.635 1.456 4.08 4.089 4.08.367.004.664.3.668.666a.667.667 0 01-.668.659C5.076 21 3 18.929 3 15.595h0V9.111C3 5.77 5.076 3.698 8.417 3.698h5.854zm1.006 6.029a.661.661 0 01.774.022.658.658 0 01.177.838h0l-2.546 3.278a.708.708 0 01-.445.254.67.67 0 01-.485-.143h0l-2.442-1.912-2.196 2.849a.66.66 0 01-.93.135.666.666 0 01-.12-.929h0l2.601-3.38a.668.668 0 01.939-.12h0l2.442 1.913 2.148-2.738.083-.067zM17.76 3.18a2.342 2.342 0 012.551.5c.67.667.872 1.672.51 2.544a2.339 2.339 0 01-2.16 1.443 2.336 2.336 0 01-2.339-2.326h0l.005-.165c.06-.878.61-1.654 1.433-1.996zm.9 1.153a1.009 1.009 0 10-.002 2.018 1.009 1.009 0 00.002-2.018z"
          fill="currentColor"
          fillRule="nonzero"
          stroke="currentColor"
          strokeWidth={0.4}
        />
      </g>
    </svg>
  );
}

export default SvgReportingIcon;
