import React, { Component } from "react";
import Loadable from "react-loadable";
import "ag-grid/dist/styles/ag-grid.css";
import "ag-grid/dist/styles/ag-theme-material.css";
import "react-datepicker/dist/react-datepicker.css";
import TopLevelNav from "./components/TopLevelNav";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import config from "./config";
import { connect } from "react-redux";
import { fetchData } from "./actions/ajaxActions";

const Loading = () => (
  <div
    style={{
      position: "absolute",
      margin: "auto",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: 100,
      height: 100,
    }}
  >
    <h2>Loading..</h2>
  </div>
);

const AccountsPage = Loadable({
  loader: () => import("./pages/accounts/"),
  loading: Loading,
});

const OpenAPIPage = Loadable({
  loader: () => import("./pages/openapi/"),
  loading: Loading,
});

const UpdatesPage = Loadable({
  loader: () => import("./pages/updates/"),
  loading: Loading,
});

const DevicesPage = Loadable({
  loader: () => import("./pages/devices/"),
  loading: Loading,
});

const BillingPage = Loadable({
  loader: () => import("./pages/billing/"),
  loading: Loading,
});

class App extends Component {
  loadNavItems = () => {
    const permissions = this.props.token.permissions
      ? this.props.token.permissions.split(",")
      : [];
    const navItems = [
      {
        label: "Venues",
        to: "/venues",
        icon: "DashboardIcon",
      },
      {
        exact: true,
        label: "OpenAPI",
        to: "/openapi",
        icon: "DashboardIcon",
      },
      {
        exact: true,
        label: "Updates",
        to: "/updates",
        icon: "DashboardIcon",
      },
      {
        exact: true,
        label: "Devices",
        to: "/devices",
        icon: "DashboardIcon",
      },
      {
        exact: true,
        label: "Billing",
        to: "/billing",
        icon: "DashboardIcon",
      },
    ];

    if (permissions.length)
      return navItems.filter((item) => permissions.includes(item.label));
    return navItems;
  };

  componentDidMount() {
    this.props.dispatch(fetchData("BILLINGSCHEMES"));
    this.props.dispatch(fetchData("USERS"));
    setTimeout(() => {
      this.checkStatus();
    }, 30000);
    window.addEventListener("beforeunload", this.unload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.unload);
  }

  unload = (e) => {
    let userid = this.props.user.user;
    // add params
    const options = {
      command: "logout",
      user: userid,
    };

    // construct URL
    let url = config.url + "/portal/php/api.php";
    let bodyParams = Object.keys(options)
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(options[key]);
      })
      .join("&");
    url = url + "?" + bodyParams;
    navigator.sendBeacon(url);
  };

  logout(error) {
    if (!error) error = "Session timed out";
    this.props.dispatch({
      error,
      type: "INVALID_TOKEN",
    });
  }

  force_logout() {
    this.props.dispatch({
      error: "Session logged out",
      type: "INVALID_TOKEN",
    });
  }

  checkStatus() {
    const opts = {
      command: "alive",
      token: this.props.user.token,
    };
    let bodyParams = Object.keys(opts)
      .map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(opts[key]);
      })
      .join("&");

    fetch(config.url + "/portal/php/api.php", {
      method: "post",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: bodyParams,
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response
            .json()
            .then((json) => {
              if (json.success) {
                setTimeout(() => {
                  this.checkStatus();
                }, 30000);
              } else {
                this.logout(json.errors[0].error);
              }
            })
            .catch((error) => {
              this.logout();
            });
        } else {
          this.logout();
        }
      })
      .catch((error) => {
        this.logout();
      });
  }

  render() {
    const navList = this.loadNavItems();
    const myNavItems = navList === undefined ? [] : navList;

    const MyAcccountsPage = (props) => {
      return <AccountsPage {...props} />;
    };

    const MyOpenAPIPage = (props) => {
      return <OpenAPIPage {...props} />;
    };

    const MyUpdatesPage = (props) => {
      return <UpdatesPage {...props} />;
    };

    const MyDevicesPage = (props) => {
      return <DevicesPage {...props} />;
    };

    const MyBillingPage = (props) => {
      return <BillingPage {...props} />;
    };

    return (
      <HashRouter>
        <Route
          render={({ location }) => {
            return (
              <div className="flex flex-grow">
                <TopLevelNav myNavItems={myNavItems} />
                <div className="flex flex-col flex-grow bg-white rounded-tl-3xl">
                  <Switch key={location.key}>
                    <Route
                      path="/openapi"
                      location={location}
                      component={MyOpenAPIPage}
                    />
                    <Route
                      path="/venues"
                      location={location}
                      component={MyAcccountsPage}
                    />
                    <Route
                      path="/updates"
                      location={location}
                      component={MyUpdatesPage}
                    />
                    <Route
                      path="/devices"
                      location={location}
                      component={MyDevicesPage}
                    />
                    <Route
                      path="/billing"
                      location={location}
                      component={MyBillingPage}
                    />
                    <Redirect from="/" to="/venues" />
                  </Switch>
                </div>
              </div>
            );
          }}
        />
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.token,
});

export default connect(mapStateToProps)(App);
