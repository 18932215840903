import * as React from 'react';

function SvgBubbleIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="icon"
      {...props}
    >
      <defs>
        <filter
          x="-125%"
          y="-115%"
          width="350%"
          height="350%"
          filterUnits="objectBoundingBox"
          id="bubble-icon_svg__a"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={8}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.17254902 0 0 0 0 0.223529412 0 0 0 0 0.411764706 0 0 0 0.00696056873 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <filter
          x="-115%"
          y="-105%"
          width="330%"
          height="330%"
          filterUnits="objectBoundingBox"
          id="bubble-icon_svg__d"
        >
          <feGaussianBlur stdDeviation={4} in="SourceAlpha" result="shadowBlurInner1" />
          <feOffset dy={-6} in="shadowBlurInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2={-1}
            k3={1}
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.528772741 0"
            in="shadowInnerInner1"
          />
        </filter>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="bubble-icon_svg__c">
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#EFF0FF" stopOpacity={0} offset="100%" />
        </linearGradient>
        <circle id="bubble-icon_svg__b" cx={10} cy={10} r={10} />
      </defs>
      <g fill="none" fillRule="evenodd" opacity={0.4}>
        <use
          fill="#000"
          filter="url(#bubble-icon_svg__a)"
          xlinkHref="#bubble-icon_svg__b"
        />
        <use fill="url(#bubble-icon_svg__c)" xlinkHref="#bubble-icon_svg__b" />
        <use
          fill="#000"
          filter="url(#bubble-icon_svg__d)"
          xlinkHref="#bubble-icon_svg__b"
        />
      </g>
    </svg>
  );
}

export default SvgBubbleIcon;
