import * as React from 'react';

function SvgSettingsIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g
          stroke="currentColor"
          strokeWidth={1.8}
          transform="translate(4 3)"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M16.026 5.061l-.56-.972a1.722 1.722 0 00-2.348-.634h0a1.714 1.714 0 01-2.348-.61 1.648 1.648 0 01-.23-.824h0A1.722 1.722 0 008.817.251H7.689a1.714 1.714 0 00-1.713 1.721h0a1.722 1.722 0 01-1.722 1.697 1.648 1.648 0 01-.824-.23h0a1.722 1.722 0 00-2.348.634l-.601.988a1.722 1.722 0 00.626 2.348h0a1.722 1.722 0 010 2.982h0a1.714 1.714 0 00-.626 2.34h0l.568.98a1.722 1.722 0 002.348.667h0a1.705 1.705 0 012.34.626c.148.25.227.534.23.824h0c0 .951.771 1.722 1.722 1.722h1.129c.947 0 1.717-.766 1.721-1.714h0a1.714 1.714 0 011.722-1.721c.29.008.573.087.824.23h0a1.722 1.722 0 002.348-.626h0l.593-.988a1.714 1.714 0 00-.626-2.348h0a1.714 1.714 0 01-.626-2.348c.15-.26.365-.477.626-.626h0a1.722 1.722 0 00.626-2.34h0v-.008z" />
          <circle cx={8.258} cy={8.9} r={2.372} />
        </g>
      </g>
    </svg>
  );
}

export default SvgSettingsIcon;
