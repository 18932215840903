import React from "react";

const Button = (props) => {
  return (
    <button
      {...props}
      className={`btn btn--${props.primary ? "primary" : "secondary"} ${
        props.className || ""
      }`}
    >
      {props.children}
      {props.icon && props.icon}
    </button>
  );
};

export { Button };
