import * as React from 'react';

function SvgAdministrationIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M9.885 14.767a25.263 25.263 0 014.317 0 14.93 14.93 0 012.334.343c1.66.335 2.744.995 3.198 1.963a3.001 3.001 0 01-.026 2.525c-.463.968-1.547 1.628-3.234 1.97-.765.173-1.542.285-2.324.335-.866.097-1.6.097-2.282.097h-.27a.764.764 0 01-.68-.761c0-.391.293-.72.68-.761h.598A23.92 23.92 0 0014 20.39a14.235 14.235 0 002.115-.3c1.197-.263 1.95-.642 2.185-1.143.184-.384.184-.83 0-1.214-.236-.51-.988-.907-2.159-1.144a13.61 13.61 0 00-2.15-.308 22.39 22.39 0 00-4.064 0c-.714.046-1.424.146-2.124.299-1.197.264-1.94.642-2.185 1.144a1.496 1.496 0 000 1.223 2.54 2.54 0 001.748 1.03h0l.102.027a.769.769 0 01.281 1.273.757.757 0 01-.767.177 3.871 3.871 0 01-2.7-1.847 2.966 2.966 0 010-2.526c.462-.994 1.546-1.636 3.216-1.98a16.032 16.032 0 012.386-.334zm.08-12.36a5.297 5.297 0 015.801 1.166 5.385 5.385 0 011.149 5.842 5.322 5.322 0 01-4.924 3.302c-2.936-.005-5.314-2.402-5.314-5.358h0l.005-.232a5.357 5.357 0 013.283-4.72zm2.026 1.124c-1.01-.002-1.98.4-2.694 1.118A3.84 3.84 0 008.18 7.36a3.837 3.837 0 002.347 3.55 3.792 3.792 0 004.155-.826 3.855 3.855 0 00.83-4.182 3.81 3.81 0 00-3.521-2.37z"
          fill="currentColor"
          fillRule="nonzero"
          stroke="currentColor"
          strokeWidth={0.4}
        />
      </g>
    </svg>
  );
}

export default SvgAdministrationIcon;
