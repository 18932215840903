import * as React from 'react';

function SvgDashboardIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M11.67 3.194a1.276 1.276 0 011.352 0c.41.256.636.728.575 1.199h0V19.86l-.019.129A1.256 1.256 0 0112.337 21a1.279 1.279 0 01-1.26-1.139h0V4.393l-.005-.141c.008-.422.228-.828.599-1.058zM4.598 8.173a1.25 1.25 0 011.335 0c.409.258.638.727.59 1.199h0v10.49l-.018.128A1.256 1.256 0 015.263 21c-.653 0-1.2-.486-1.258-1.139h0V9.371l-.006-.14c.007-.423.227-.826.598-1.058zm14.041 5.51a1.273 1.273 0 011.943 1.2h0v4.978l-.021.129A1.276 1.276 0 0119.32 21c-.654 0-1.199-.486-1.26-1.139h0v-4.979l-.01-.132a1.224 1.224 0 01.587-1.067z"
        fill="currentColor"
        fillRule="nonzero"
        stroke="currentColor"
        strokeWidth={0.2}
      />
    </svg>
  );
}

export default SvgDashboardIcon;
