import * as React from 'react';

function SvgCheckmarkIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <path
        d="M9.846 18.013a.997.997 0 01-.728-.319L4.27 12.537a.999.999 0 011.457-1.366l4.11 4.379 8.388-9.177A.997.997 0 1119.7 7.71l-9.117 9.974a.997.997 0 01-.728.33h-.01z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgCheckmarkIcon;
