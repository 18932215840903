import * as React from 'react';

function SvgResetIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M10.698 6.259a1.077 1.077 0 011.518 1.518l-4.62 4.626 4.62 4.625a1.077 1.077 0 01-1.518 1.519l-5.385-5.385a1.077 1.077 0 010-1.518zm6.461 0a1.077 1.077 0 011.519 1.518l-4.62 4.626 4.62 4.625a1.077 1.077 0 01-1.519 1.519l-5.384-5.385a1.077 1.077 0 010-1.518z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgResetIcon;
